<template>
    <div
        class="row transition"
        :style="`max-height: ${openThongTinMoRong ? `1000px` : `32px`}; overflow: hidden`"
    >
        <div class="xs12">
            <div
                class="row align-center font-16 font-medium"
                @click="openThongTinMoRong = !openThongTinMoRong"
            >
                <i
                    class="mdi mdi-menu-down-outline transition"
                    :style="`transform: rotate( ${openThongTinMoRong ? `180` : `0`}deg);`"
                ></i>
                Thông tin mở rộng
            </div>
            <div class="row mt-1 mb-4">
                <div class="xs12">
                    <DxSelectBox
                        v-model:value="KyLenhTuyChon_ThongTinChung.IdNhaXe"
                        label="Nhà xe"
                        :data-source="DanhSachNhaXeSelect"
                        value-expr="IdNhaXe"
                        display-expr="TenNhaXe"
                        labelMode="floating"
                        styling-mode="underlined"
                        validationMessageMode="always"
                        :search-enabled="true"
                        :showClearButton="true"
                    >
                        <!-- <DxValidator>
                            <DxRequiredRule
                                message="Nhà xe không được bỏ trống!"
                            />
                        </DxValidator> -->
                    </DxSelectBox>
                </div>
            </div>

            <div class="row font-medium color-primary mb-1">
                Cài đặt thông tin dữ liệu nhà xe được phép chỉnh sửa khi sửa lệnh
            </div>
            <div
                class="row mb-3"
                v-for="(item, index) in DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh"
                :key="index"
            >
                <DxCheckBox
                    v-model="item.select"
                    :text="item.TenThuocTinh"
                    class="mr-3"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { DxCheckBox, DxSelectBox } from "devextreme-vue";
export default {
    components: { DxCheckBox, DxSelectBox },
    props: {},
    data() {
        return {
            openThongTinMoRong: true,
        };
    },
    computed: {
        KyLenhTuyChon_ThongTinChung: {
            get() {
                return this.$store.state.LenhTuyChon.KyLenhTuyChon_ThongTinChung || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinChung",
                    data: data,
                });
            },
        },
        DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh: {
            get() {
                return (
                    this.$store.state.LenhTuyChon
                        .DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh || {}
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh",
                    data: data,
                });
            },
        },
        DanhSachNhaXeSelect() {
            return this.$store.state.ThongTinLaiXe.DanhSachNhaXeSelect;
        },
    },
    watch: {},
    methods: {
        async LayCauHinhChinhSuaKhiKyLenhTheoTuyenVaBKS() {
            if (
                !this.KyLenhTuyChon_ThongTinChung.ID_DnvtTuyen ||
                !this.KyLenhTuyChon_ThongTinChung.ID_DnvtXe
            ) {
                return;
            }
            let data = await this.LayCauHinhChinhSuaKhiKyLenh({
                IdDnvtTuyen: this.KyLenhTuyChon_ThongTinChung.ID_DnvtTuyen,
                IdDnvtXe: this.KyLenhTuyChon_ThongTinChung.ID_DnvtXe,
                IdNhaXe: this.KyLenhTuyChon_ThongTinChung.IdNhaXe,
            });
            if (data.length > 0) {
                this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh = this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh.map(
                    (e) => {
                        let check = data.some((dt) => dt.MaThuocTinh == e.MaThuocTinh);
                        e.select = check;
                        return e;
                    },
                );
            }
        },
        async LayTatCaCauHinhChinhSuaKhiKyLenh() {
            let data = await this.LayCauHinhChinhSuaKhiKyLenh({
                IdDnvtTuyen: null,
                IdDnvtXe: null,
                IdNhaXe: null,
            });
            if (data.length > 0) {
                this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh = data.map((e) => {
                    e.select = true;
                    return e;
                });
            }
        },
        async LayCauHinhChinhSuaKhiKyLenh({ IdDnvtTuyen, IdDnvtXe, IdNhaXe }) {
            try {
                let rs = await this.$store.dispatch(
                    "DVVTLenh/LayCauHinhChinhSuaKhiKyLenh",
                    {
                        IdDnvtTuyen: IdDnvtTuyen || null,
                        IdDnvtXe: IdDnvtXe || null,
                        IdNhaXe: IdNhaXe || null,
                    },
                );

                if (!rs.status) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        "Lấy cấu hình chỉnh sửa khi ký lệnh thất bại! " + rs.message,
                    );
                }
                return rs.data || [];
            } catch (error) {
                console.log(error);
            }
        },
        async LayDanhSachNhaXe() {
            try {
                let rs = await this.$store.dispatch("ThongTinLaiXe/LayDanhSachNhaXe");

                if (!rs.status) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        `Lấy danh sách nhà xe thất bại! ${rs.message}`,
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    created() {
        this.LayTatCaCauHinhChinhSuaKhiKyLenh();
        this.LayDanhSachNhaXe();
        let self = this;
        this.emitter.on("LayCauHinhChinhSuaKhiKyLenhTheoTuyenVaBKS", () => {
            self.LayCauHinhChinhSuaKhiKyLenhTheoTuyenVaBKS();
        });
    },
    mounted() {},
    beforeUnmount() {
        this.emitter.off("LayCauHinhChinhSuaKhiKyLenhTheoTuyenVaBKS");
    },
};
</script>
<style scoped>
:deep(.dx-tagbox.dx-texteditor.dx-editor-underlined::after) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
:deep(.dx-tagbox.dx-texteditor.dx-state-active::before),
:deep(.dx-tagbox.dx-texteditor.dx-state-focused::before) {
    border-bottom: 2px solid #03a9f4 !important;
    transform: scale(1) !important;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.02, 1) !important;
}
</style>
